<div class="container">
    <h2 i18n>Default SDS settings</h2>
    <span class="text-xs text-gray-700"
        >These settings are used by default for all HP SDS devices if device-specific SDS settings
        have not been configured.</span
    >

    <div class="mt-4">
        <div class="notification-banner-container m-t-0" *ngIf="!(sdsConnected$ | async)">
            <div class="flex items-center space-x-2 bg-orange-200 text-orange-900 rounded py-2 px-4">
                <mat-icon class="w-auto h-6 flex-shrink-0 text-orange-900 fill-orange-950">warning</mat-icon>
                <ng-container i18n class="flex-1">
                    This install is not connected to HP SDS. If you've just enabled the HP SDS
                    integration, it could take several minutes before the connection is established.
                </ng-container>
            </div>
        </div>
        <div class="notification-banner-container m-t-0" *ngIf="sdsConnected$ | async">
            <div class="flex items-center space-x-2 bg-neutral-200 rounded py-2 px-4">
                <mat-icon class="w-auto h-6 flex-shrink-0">info</mat-icon>
                <ng-container i18n class="flex-1">
                    For security reasons, we do not show the current state of these credentials. If
                    you have already configured HP SDS settings in the past, then those settings
                    will be used by HP SDS despite not being shown below.
                </ng-container>
            </div>
        </div>
    </div>

    <div class="mt-4">
        <mat-accordion>
            <!-- Admin Credentials Panel -->
            <mat-expansion-panel [disabled]="!(sdsConnected$ | async)">
                <mat-expansion-panel-header>
                    <mat-panel-title i18n>
                        <div>
                            <mat-icon
                                *ngIf="adminCredentialFormValid$ | async"
                                class="online pull-left status-icon mr-2"
                                >check_circle</mat-icon
                            >
                            <span>Admin credentials</span>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <form [formGroup]="adminCredentialForm" class="flex space-x-2">
                    <ptkr-field-group label="Username" i18n-label>
                        <mat-form-field appearance="outline">
                            <input matInput autocomplete="off" type="text" formControlName="username" placeholder="Admin"/>
                        </mat-form-field>
                    </ptkr-field-group>

                    <ptkr-field-group label="Password" i18n-label>
                        <mat-form-field appearance="outline">
                            <input matInput autocomplete="off" type="password" formControlName="password" />
                        </mat-form-field>
                    </ptkr-field-group>
                </form>
            </mat-expansion-panel>

            <!-- SNMP V1/V2 Read Panel -->
            <mat-expansion-panel [disabled]="!(sdsConnected$ | async)">
                <mat-expansion-panel-header>
                    <mat-panel-title i18n>
                        <div>
                            <mat-icon
                                *ngIf="snmpV1v2ReadFormValid$ | async"
                                class="online pull-left status-icon mr-2"
                                >check_circle</mat-icon
                            >
                            <span>SNMP V1/V2 read</span>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <form [formGroup]="snmpV1v2ReadForm" class="flex space-x-2">
                    <ptkr-field-group label="Community" i18n-label>
                        <mat-form-field appearance="outline">
                            <input matInput type="text" formControlName="readCommunity" />
                        </mat-form-field>
                    </ptkr-field-group>
                </form>
            </mat-expansion-panel>

            <!-- SNMP V1/V2 Write Panel -->
            <mat-expansion-panel [disabled]="!(sdsConnected$ | async)">
                <mat-expansion-panel-header>
                    <mat-panel-title i18n>
                        <div>
                            <mat-icon
                                *ngIf="snmpV1v2WriteFormValid$ | async"
                                class="online pull-left status-icon mr-2"
                                >check_circle</mat-icon
                            >
                            <span>SNMP V1/V2 write</span>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <form [formGroup]="snmpV1v2WriteForm" class="flex space-x-2">
                    <ptkr-field-group label="Community" i18n-label>
                        <mat-form-field appearance="outline">
                            <input matInput type="text" formControlName="writeCommunity" />
                        </mat-form-field>
                    </ptkr-field-group>
                </form>
            </mat-expansion-panel>

            <!-- SNMP V3 Settings Panel -->
            <mat-expansion-panel [disabled]="!(sdsConnected$ | async)">
                <mat-expansion-panel-header>
                    <mat-panel-title i18n>
                        <div>
                            <mat-icon
                                *ngIf="snmpV3FormValid$ | async"
                                class="online pull-left status-icon mr-2"
                                >check_circle</mat-icon
                            >
                            <span>SNMP V3</span>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <form [formGroup]="snmpV3Form" class="grid grid-cols-1 md:grid-cols-2 gap-2">
                    <ptkr-field-group label="Context" i18n-label class="col-span-1">
                        <mat-form-field appearance="outline" class="w-full">
                            <input matInput autocomplete="off" type="text" formControlName="context" placeholder="Jetdirect"/>
                        </mat-form-field>
                    </ptkr-field-group>

                    <ptkr-field-group label="Username" i18n-label class="col-span-1">
                        <mat-form-field appearance="outline" class="w-full">
                            <input matInput autocomplete="off" type="text" formControlName="username" />
                        </mat-form-field>
                    </ptkr-field-group>

                    <ptkr-field-group label="Authentication protocol" i18n-label class="col-span-1">
                        <mat-form-field appearance="outline" class="w-full">
                            <mat-select formControlName="authenticationProtocol">
                                <mat-option value="MD5">MD5</mat-option>
                                <mat-option value="SHA1">SHA</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ptkr-field-group>

                    <ptkr-field-group label="Authentication password" i18n-label class="col-span-1">
                        <mat-form-field appearance="outline" class="w-full">
                            <input
                                matInput
                                autocomplete="off"
                                type="password"
                                formControlName="authenticationPassword"
                            />
                        </mat-form-field>
                    </ptkr-field-group>

                    <ptkr-field-group label="Privacy protocol" i18n-label class="col-span-1">
                        <mat-form-field appearance="outline" class="w-full">
                            <mat-select formControlName="privacyProtocol">
                                <mat-option value="AES128">AES</mat-option>
                                <mat-option value="DES">DES</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ptkr-field-group>

                    <ptkr-field-group label="Privacy password" i18n-label class="col-span-1">
                        <mat-form-field appearance="outline" class="w-full">
                            <input matInput autocomplete="off" type="password" formControlName="privacyPassword" />
                        </mat-form-field>
                    </ptkr-field-group>
                </form>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div class="mt-4 flex justify-end">
        <button
            mat-flat-button
            color="primary"
            i18n
            (click)="set()"
            [disabled]="
                !snmpV1v2ReadForm.valid &&
                !snmpV1v2WriteForm.valid &&
                !snmpV3Form.valid &&
                !adminCredentialForm.valid
            "
        >
            Set settings
        </button>
    </div>
</div>
