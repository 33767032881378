import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstallService } from '@app/services';
import { BehaviorSubject } from 'rxjs';
import { ISdsSetDefaultDeviceSettingsModel } from '@server/library/dto';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InstallSdsStatus } from '@libs/iso/core';

@Component({
    selector: 'ptkr-sds-set-default-settings-modal',
    templateUrl: './sds-set-default-settings-modal.component.html',
    styleUrls: ['./sds-set-default-settings-modal.component.scss']
})
export class SdsSetDefaultSettingsModalComponent implements OnInit {
    snmpV1v2ReadForm: FormGroup;
    snmpV1v2WriteForm: FormGroup;
    snmpV3Form: FormGroup;
    adminCredentialForm: FormGroup;

    sdsState$ = new BehaviorSubject(null);
    sdsConnected$ = new BehaviorSubject(false);
    adminCredentialFormValid$ = new BehaviorSubject<boolean>(false);
    snmpV1v2ReadFormValid$ = new BehaviorSubject<boolean>(false);
    snmpV1v2WriteFormValid$ = new BehaviorSubject<boolean>(false);
    snmpV3FormValid$ = new BehaviorSubject<boolean>(false);

    adminCredentialsSet = false;
    snmpV1v2ReadSet = false;
    snmpV1v2WriteSet = false;
    snmpV3Set = false;

    constructor(
        public dialogRef: MatDialogRef<SdsSetDefaultSettingsModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { installKey: string },
        private fb: FormBuilder,
        private _installService: InstallService
    ) {}

    ngOnInit(): void {
        this.initForms();
    }

    private initForms(): void {
        this.snmpV1v2ReadForm = this.fb.group({
            readCommunity: ['', Validators.required]
        });

        this.snmpV1v2WriteForm = this.fb.group({
            writeCommunity: ['', Validators.required]
        });

        this.snmpV3Form = this.fb.group({
            authenticationProtocol: ['', Validators.required],
            authenticationPassword: ['', Validators.required],
            privacyProtocol: ['', Validators.required],
            privacyPassword: ['', Validators.required],
            username: ['', Validators.required],
            context: ['', Validators.required]
        });

        this.adminCredentialForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // Set up some subscriptions that whether the form is valid or not
        this.snmpV1v2ReadForm.valueChanges.subscribe(() => {
            this.snmpV1v2ReadFormValid$.next(this.snmpV1v2ReadSet || this.snmpV1v2ReadForm.valid);
        });
        this.snmpV1v2WriteForm.valueChanges.subscribe(() => {
            this.snmpV1v2WriteFormValid$.next(
                this.snmpV1v2WriteSet || this.snmpV1v2WriteForm.valid
            );
        });
        this.snmpV3Form.valueChanges.subscribe(() => {
            this.snmpV3FormValid$.next(this.snmpV3Set || this.snmpV3Form.valid);
        });
        this.adminCredentialForm.valueChanges.subscribe(() => {
            this.adminCredentialFormValid$.next(
                this.adminCredentialsSet || this.adminCredentialForm.valid
            );
        });

        // Get the current settings state
        this._installService
            .getSdsDefaultDeviceSettingsState(this.data.installKey)
            .subscribe(state => {
                console.log(state.sdsState);
                this.sdsState$.next(state.sdsState);
                if (state.sdsState === InstallSdsStatus.Connected) {
                    this.sdsConnected$.next(true);
                }

                // Save the state separately from the validity
                this.adminCredentialsSet = state.admin;
                this.snmpV1v2ReadSet = state.snmpV1v2Read;
                this.snmpV1v2WriteSet = state.snmpV1v2Write;
                this.snmpV3Set = state.snmpV3;

                this.snmpV1v2ReadFormValid$.next(state.snmpV1v2Read);
                this.snmpV1v2WriteFormValid$.next(state.snmpV1v2Write);
                this.snmpV3FormValid$.next(state.snmpV3);
                this.adminCredentialFormValid$.next(state.admin);
            });
    }

    public set() {
        const settings: ISdsSetDefaultDeviceSettingsModel = {
            snmpV1v2: this.snmpV1v2ReadForm.valid || this.snmpV1v2WriteForm.valid ? {} : undefined,
            snmpV3: this.snmpV3Form.valid ? this.snmpV3Form.value : undefined,
            admin: this.adminCredentialForm.valid ? this.adminCredentialForm.value : undefined
        };

        if (this.snmpV1v2ReadForm.valid) {
            settings.snmpV1v2.readCommunity = this.snmpV1v2ReadForm.value.readCommunity;
        }
        if (this.snmpV1v2WriteForm.valid) {
            settings.snmpV1v2.writeCommunity = this.snmpV1v2WriteForm.value.writeCommunity;
        }

        this.dialogRef.close(settings);
    }
}
