import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ConfirmMultipleModalComponent } from './confirm-multiple-modal/confirm-multiple-modal.component';
import { NotificationOverlayComponent } from './notification-overlay/notification-overlay.component';
import { ProfileOverlayComponent } from './profile-overlay/profile-overlay.component';
import { SelectEntityOverlayComponent } from './select-entity-overlay/select-entity-overlay.component';
import { DeviceAddManuallyModalComponent } from '@app/shared/overlays/device-add-manually-modal/device-add-manually-modal.component';
import { OrderTonerOverlayComponent } from './order-toner-overlay/order-toner-overlay.component';
import { EventNoteComponent } from './event-note/event-note.component';
import { ViewInvoiceComponent } from './view-invoice/view-invoice.component';
import { BulkEntityItemsComponent } from './bulk-entity-items/bulk-entity-items.component';
import { EntityItemsStepComponent } from './bulk-entity-items/entity-items-step/entity-items-step.component';
import { VerifyAchComponent } from './verify-ach/verify-ach.component';
import { ShippingAddressModalComponent } from './shipping-address-modal/shipping-address-modal.component';
import { PaymentMethodModalComponent } from './payment-method-modal/payment-method-modal.component';
import { CheckoutOverlayComponent } from './checkout-overlay/checkout-overlay.component';
import { ConfirmStepComponent as CheckoutConfirmStepComponent } from './checkout-overlay/confirm-step/confirm-step.component';
import { PaymentStepComponent } from '@app/shared/overlays/checkout-overlay/payment-step/payment-step.component';
import { ReviewStepComponent } from '@app/shared/overlays/checkout-overlay/review-step/review-step.component';
import { ShippingStepComponent } from '@app/shared/overlays/checkout-overlay/shipping-step/shipping-step.component';
import { InputPromptComponent } from '@app/shared/overlays/input-prompt/input-prompt.component';
import { WarrantyOverlayComponent } from '@app/shared/overlays/warranty-overlay/warranty-overlay.component';
import { SpecialMessageComponent } from './special-message/special-message.component';
import { JobsModalComponent } from '@app/shared/overlays/jobs-modal/jobs-modal.component';
import { ConfirmSettingsModalComponent } from '@app/shared/overlays/confirm-settings-modal/confirm-settings-modal.component';
import { PostDownloadModalComponent } from '@app/shared/overlays/post-download-modal/post-download-modal.component';
import { BulkDeviceImportComponent } from '@app/shared/overlays/bulk-device-import/bulk-device-import.component';
import { BulkDeviceUpdateComponent } from '@app/shared/overlays/bulk-device-update/bulk-device-update.component';
import { WelcomeDcaSetupOverlayComponent } from '@app/shared/overlays/welcome-dca-setup-overlay/welcome-dca-setup-overlay.component';
import { DeleteEntityModalComponent } from './delete-entity-modal/delete-entity-modal.component';
import { IpSnmpDumpModalComponent } from './ip-snmp-dump-modal/ip-snmp-dump-modal.component';
import { EditColumnsModalComponent } from './edit-columns-modal/edit-columns-modal.component';
import { EditLabelModalComponent } from './edit-label-modal/edit-label-modal.component';
import { DeleteLabelModalComponent } from './delete-label-modal/delete-label-modal.component';
import { LabelEditorModalComponent } from './label-editor-modal/label-editor-modal.component';
import { EditBillingMeterModalComponent } from './edit-billing-meter-modal/edit-billing-meter-modal.component';
import { RadioMeterSelectorModalComponent } from './radio-meter-selector-modal/radio-meter-selector-modal.component';
import { JobComponent } from './jobs-modal/job/job.component';
import { InputModalWithContentComponent } from './input-modal-with-content/input-modal-with-content.component';
import { RestartJobConfirmationComponent } from './restart-job-confirmation/restart-job-confirmation.component';
import { DGIApplyAndTestConfirmationComponent } from './dgi-apply-and-test-confirmation-modal/dgi-apply-and-test-confirmation.component';
import { JobLogViewerModalComponent } from './job-log-viewer-modal/job-log-viewer-modal.component';
import { EditReportScheduleModalComponent } from './edit-report-schedule-modal/edit-report-schedule-modal.component';
import { ReportScheduledEventsModalComponent } from './report-scheduled-events-modal/report-scheduled-events-modal.component';
import { DeviceMeterHistoryQueryModalComponent } from './device-meter-history-query-modal/device-meter-history-query-modal.component';
import { TextareaModalWithContentComponent } from './textarea-modal-with-content/textarea-modal-with-content.component';
import { SdsSetDefaultSettingsModalComponent } from './sds-set-default-settings-modal/sds-set-default-settings-modal.component';

export const overlays = [
    JobsModalComponent,
    ConfirmModalComponent,
    ConfirmMultipleModalComponent,
    DeviceAddManuallyModalComponent,
    NotificationOverlayComponent,
    ProfileOverlayComponent,
    SelectEntityOverlayComponent,
    OrderTonerOverlayComponent,
    EventNoteComponent,
    ViewInvoiceComponent,
    BulkEntityItemsComponent,
    EntityItemsStepComponent,
    VerifyAchComponent,
    ShippingAddressModalComponent,
    PaymentMethodModalComponent,
    CheckoutOverlayComponent,
    CheckoutConfirmStepComponent,
    PaymentStepComponent,
    ReviewStepComponent,
    ShippingStepComponent,
    InputPromptComponent,
    WarrantyOverlayComponent,
    SpecialMessageComponent,
    ConfirmSettingsModalComponent,
    PostDownloadModalComponent,
    BulkDeviceImportComponent,
    BulkDeviceUpdateComponent,
    WelcomeDcaSetupOverlayComponent,
    DeleteEntityModalComponent,
    IpSnmpDumpModalComponent,
    EditColumnsModalComponent,
    EditLabelModalComponent,
    DeleteLabelModalComponent,
    LabelEditorModalComponent,
    EditBillingMeterModalComponent,
    RadioMeterSelectorModalComponent,
    ShippingAddressModalComponent,
    PaymentMethodModalComponent,
    BulkDeviceImportComponent,
    BulkDeviceUpdateComponent,
    WelcomeDcaSetupOverlayComponent,
    EditColumnsModalComponent,
    DeleteLabelModalComponent,
    JobComponent,
    ShippingAddressModalComponent,
    PaymentMethodModalComponent,
    JobComponent,
    BulkDeviceImportComponent,
    BulkDeviceUpdateComponent,
    WelcomeDcaSetupOverlayComponent,
    EditColumnsModalComponent,
    DeleteLabelModalComponent,
    InputModalWithContentComponent,
    RestartJobConfirmationComponent,
    DGIApplyAndTestConfirmationComponent,
    JobLogViewerModalComponent,
    EditReportScheduleModalComponent,
    ReportScheduledEventsModalComponent,
    DeviceMeterHistoryQueryModalComponent,
    TextareaModalWithContentComponent,
    SdsSetDefaultSettingsModalComponent
];

export {
    JobsModalComponent,
    ConfirmModalComponent,
    ConfirmMultipleModalComponent,
    DeviceAddManuallyModalComponent,
    NotificationOverlayComponent,
    ProfileOverlayComponent,
    SelectEntityOverlayComponent,
    OrderTonerOverlayComponent,
    EventNoteComponent,
    ViewInvoiceComponent,
    BulkEntityItemsComponent,
    EntityItemsStepComponent,
    VerifyAchComponent,
    ShippingAddressModalComponent,
    PaymentMethodModalComponent,
    CheckoutOverlayComponent,
    CheckoutConfirmStepComponent,
    PaymentStepComponent,
    ReviewStepComponent,
    ShippingStepComponent,
    InputPromptComponent,
    WarrantyOverlayComponent,
    SpecialMessageComponent,
    ConfirmSettingsModalComponent,
    PostDownloadModalComponent,
    BulkDeviceImportComponent,
    BulkDeviceUpdateComponent,
    WelcomeDcaSetupOverlayComponent,
    DeleteEntityModalComponent,
    IpSnmpDumpModalComponent,
    EditColumnsModalComponent,
    EditLabelModalComponent,
    DeleteLabelModalComponent,
    LabelEditorModalComponent,
    EditBillingMeterModalComponent,
    RadioMeterSelectorModalComponent as RadioSelectorModalComponent,
    JobComponent,
    InputModalWithContentComponent,
    RestartJobConfirmationComponent,
    DGIApplyAndTestConfirmationComponent,
    JobLogViewerModalComponent,
    EditReportScheduleModalComponent,
    ReportScheduledEventsModalComponent,
    DeviceMeterHistoryQueryModalComponent,
    TextareaModalWithContentComponent,
    SdsSetDefaultSettingsModalComponent
};
