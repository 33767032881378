import { DeviceReport, Item, AuditLogItem, Settings, ISettings, MeterRead, DeviceSdsId, IDeviceSdsId } from '@libs/iso/core';
import { DeviceFirmware } from '@libs/iso/core/models/device/DeviceFirmware';
import { ObjectId } from 'mongodb';
import * as Moment from 'moment';

export interface DeviceLineItemSummaryModel {
    _id: string | ObjectId;
    installKey: string[];
    entityKey: string;
    createdDate: Date;
    make: string;
    model: string;
    name: string;
    ipAddr: string;
    macAddr: string;
    hostnames: string[];
    systemName: string;
    serialNum: string;
    customSerialNum: string;
    assetId: string;
    location: string;
    customLocation: string;
    alerts: Event[];
    supplyAlertEnabled: boolean;
    supplyAlertLevel: number;
    supplyAlertEmails: string[];
    serviceAlertEnabled: boolean;
    serviceAlertLevel: number;
    serviceAlertEmails: string[];
    history: AuditLogItem[];
    selected: boolean;
    compatible: Item[];
    trackingStatus: boolean;
    sourceInstall: string;
    isLocal: boolean;
    isUsbPlus: boolean;
    entityName: string;
    meterReadDtTm: Date | Moment.Moment;
    currentMeterRead: MeterRead;
    note: string;
    integrationID: string;
    firstBillingDate: Date | Moment.Moment;
    total: number;
    totalBlack: number;
    totalColor: number;
    totalScans: number;
    firmware: DeviceFirmware;
    formats: string[];
    dynamicDeviceGroup?: string;
    sdsDeviceIds: {[installKey: string]: IDeviceSdsId};

    getWarnings(): string[];
    getFormattedWarnings(): string;
    isConnectedToSds(): boolean;
}

interface DeviceLineItemSummaryConstructorParams extends DeviceLineItemSummaryModel {}

export class DeviceLineItemSummary implements DeviceLineItemSummaryModel {
    public _id: string | ObjectId = null;
    public installKey: string[] = [];
    public entityKey: string = '';
    public make: string = '';
    public createdDate: Date;
    public model: string = '';
    public name: string = '';
    public ipAddr: string = '';
    public macAddr: string = '';
    public hostnames: string[] = [];
    public systemName: string = '';
    public serialNum: string = '';
    public customSerialNum: string = null;
    public assetId: string = '';
    public location: string = '';
    public customLocation: string = '';
    public alerts: Event[] = [];
    public supplyAlertEnabled: boolean = false;
    public supplyAlertLevel: number;
    public supplyAlertEmails: string[] = [];
    public serviceAlertEnabled: boolean = false;
    public serviceAlertLevel: number;
    public serviceAlertEmails: string[] = [];
    public history: AuditLogItem[] = [];
    public selected: boolean = false;
    public compatible: Item[] = [];
    public trackingStatus: boolean = true;
    public sourceInstall: string = '';
    public isLocal: boolean = false;
    public isUsbPlus: boolean = false;
    public entityName: string = '';
    public meterReadDtTm: Date | Moment.Moment = null;
    public currentMeterRead: MeterRead = null;
    public note: string = '';
    public integrationID: string = '';
    public firstBillingDate: Date | Moment.Moment;
    public total: number;
    public totalBlack: number;
    public totalColor: number;
    public totalScans: number;
    public firmware: DeviceFirmware;
    public formats: string[];
    public dynamicDeviceGroup?: string;
    public sdsDeviceIds: {[installKey: string]: DeviceSdsId} = {};

    constructor(params?: Partial<DeviceLineItemSummaryConstructorParams>) {
        if (!!params) {
            if (params._id) {
                this._id = params._id || this._id;
            }
            this.entityKey = params.entityKey.toString() ?? this.entityKey;
            if (params.installKey) {
                this.installKey = params.installKey || this.installKey;
            }
            if (params.alerts) {
                this.alerts = [];
                this.history = params.history;
            }
            if (Array.isArray(params.compatible)) {
                this.compatible = params.compatible.map(c => new Item(c));
            }
            if (!!params.createdDate) {
                this.createdDate = new Date(params.createdDate);
            }
            this.trackingStatus =
                params.trackingStatus != null ? params.trackingStatus : this.trackingStatus;
            this.sourceInstall = params.sourceInstall ?? this.sourceInstall;
            this.supplyAlertEnabled =
                params.supplyAlertEnabled !== undefined
                    ? params.supplyAlertEnabled
                    : this.supplyAlertEnabled;
            this.supplyAlertLevel = params.supplyAlertLevel || this.supplyAlertLevel;
            this.supplyAlertEmails = params.supplyAlertEmails || this.supplyAlertEmails;
            this.serviceAlertEnabled =
                params.serviceAlertEnabled !== undefined
                    ? params.serviceAlertEnabled
                    : this.serviceAlertEnabled;
            this.serviceAlertLevel = params.serviceAlertLevel || this.serviceAlertLevel;
            this.serviceAlertEmails = params.serviceAlertEmails || this.serviceAlertEmails;
            this.serialNum = params.serialNum || this.serialNum;
            this.customSerialNum = params.customSerialNum ?? this.customSerialNum;
            this.selected = params.selected || this.selected;
            this.isLocal = params.isLocal || this.isLocal;
            this.isUsbPlus = params.isUsbPlus || this.isUsbPlus;
            this.entityName = params.entityName || this.entityName;
            this.assetId = params.assetId || this.assetId;
            this.location = params.location ?? this.location;
            this.customLocation = params.customLocation ?? this.customLocation;
            this.make = params.make || this.make;
            this.model = params.model || this.model;
            this.name = params.name || this.name;
            this.ipAddr = params.ipAddr || this.ipAddr;
            this.macAddr = params.macAddr || this.macAddr;
            this.hostnames = params.hostnames || this.hostnames;
            this.systemName = params.systemName || this.systemName;
            this.meterReadDtTm = params.meterReadDtTm || this.meterReadDtTm;
            this.currentMeterRead =
                params.currentMeterRead != null
                    ? new MeterRead(params.currentMeterRead)
                    : this.currentMeterRead;
            this.note = params.note || this.note;
            this.integrationID = params.integrationID || this.integrationID;
            this.firstBillingDate = Moment(params.firstBillingDate) || this.firstBillingDate;
            this.total = params.total ?? this.total;
            this.totalBlack = params.totalBlack ?? this.totalBlack;
            this.totalColor = params.totalColor ?? this.totalColor;
            this.totalScans = params.totalScans ?? this.totalScans;
            this.firmware = params.firmware ?? this.firmware;
            this.formats = params.formats ?? this.formats;
            this.dynamicDeviceGroup = params.dynamicDeviceGroup;
            if (params.sdsDeviceIds) {
                for (const installKey in params.sdsDeviceIds) {
                    this.sdsDeviceIds[installKey] = new DeviceSdsId(params.sdsDeviceIds[installKey]);
                }
            }
        }
    }

    public getWarnings(): string[] {
        const warnings = [];
        const hasHighFillRates =
            this.currentMeterRead?.supplyEntries().filter(e => e.fillRate > 0.07).length ?? 0;
        if (hasHighFillRates > 0) {
            switch (hasHighFillRates) {
                case 1:
                    warnings.push('1 supply has a higher-than-expected fill rate');
                    break;
                default:
                    warnings.push(
                        `${hasHighFillRates} supplies have higher-than-expected fill rates`
                    );
                    break;
            }
        }
        return warnings;
    }

    public getFormattedWarnings(): string {
        return this.getWarnings().reduce((acc, next) => (acc += next + '\n'), '');
    }

    public isConnectedToSds(): boolean {
        return Object.keys(this.sdsDeviceIds).length > 0;
    }
}
