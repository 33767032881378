export interface IDeviceSdsId {
    sdsCustomerId: number;
    sdsDeviceId: number;
}

export class DeviceSdsId implements IDeviceSdsId {
    public sdsCustomerId: number;
    public sdsDeviceId: number;

    constructor(data: IDeviceSdsId) {
        this.sdsCustomerId = data.sdsCustomerId;
        this.sdsDeviceId = data.sdsDeviceId;
    }
}
