import { InstallSdsStatus } from '@libs/iso/core';

export interface IInstallSds {
    customerId?: number;
    connectorId?: number;
}

export class InstallSds implements IInstallSds {
    public customerId?: number;
    public connectorId?: number;

    constructor(data: IInstallSds) {
        this.customerId = data.customerId;
        this.connectorId = data.connectorId;
    }

    public status(): InstallSdsStatus {
        if (this.customerId && this.connectorId) {
            return InstallSdsStatus.Connected;
        } else if (this.customerId && !this.connectorId) {
            return InstallSdsStatus.Disconnected;
        } else {
            return InstallSdsStatus.Disabled;
        }
    }
}
