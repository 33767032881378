import { DeviceSkipAlert, IDeviceSkipAlert } from '@libs/iso/core/models/device/DeviceSkipAlert';
import { BaseModel, IBaseModel } from '../BaseModel';
import { Column, colKeyOneProp } from '../../helpers/columns';
import { ObjectId } from 'bson';
import { Settings } from '../settings/Settings';
import { DeviceEventModel, DeviceEvent } from './DeviceEvent';
import { MeterRead, IMeterRead } from '@libs/iso/core/models/meterRead/MeterRead';
import { Status } from 'libs/rfx-server/db/models';
import { DeviceLabels } from '../../enums';
import { CollectionName } from '../../enums/CollectionName';
import { DeviceFirmware } from './DeviceFirmware';
import { DeviceSdsId, IDeviceSdsId } from '@libs/iso/core';

export interface DeviceModel extends IBaseModel {
    entityKey: ObjectId | string;
    installKey: string[];
    itemKey: ObjectId | string;
    name: string;
    assetId: string;
    location: string;
    customLocation: string;
    macAddr: string;
    uMacId?: string;
    ipAddr: string;
    model: string;
    make: string;
    serialNum: string;
    customSerialNum: string;
    manualSerialNum: {
        value: string;
        date: Date;
    };
    note: string;
    avgFill: number;
    avgColorFill: number;
    firmware: DeviceFirmware;
    counterType: string;
    ignore30Rule: boolean;
    lastReportDtTm: Date;
    currentMeterRead: IMeterRead;
    events: DeviceEventModel[];
    settings: Partial<Settings>;
    label: Array<DeviceLabels>;
    status: Status;
    lcdDisplay: string;
    isLocal: boolean;
    isUsbPlus: boolean;
    skipAlerts: IDeviceSkipAlert[];
    integrationID: string;
    dynamicDeviceGroup?: string;
    sdsDeviceIds: { [installKey: string]: IDeviceSdsId };
}

interface DeviceConstructorParams extends DeviceModel {}

export class Device extends BaseModel implements DeviceModel {
    public static columns: Array<Column> = [
        ...BaseModel.columns,
        {
            name: 'Entity Name',
            keys: colKeyOneProp('name', CollectionName.entity),
            description: 'Name of the entity this device is under',
            display: Column.displayFunctions[''],
            sortable: true
        },
        {
            name: 'Firmware',
            keys: colKeyOneProp('firmware'),
            description: "Device's firmware version",
            display: Column.displayFunctions[''],
            sortable: true
        }
        //     ...Column.extrapolate(
        //         [
        //             {
        //                 name: '',
        //                 key: '',
        //                 description: '',
        //                 display: Column.displayFunctions[''],
        //                 sortable: true
        //             }
        //         ],
        //         [
        //             {
        //                 override: {
        //                     name: 'Entity Key',
        //                     key: 'entityKey',
        //                     description: ''
        //                 }
        //             },
        //             {
        //                 override: {
        //                     name: 'Install Key',
        //                     key: 'installKey',
        //                     description: ''
        //                 }
        //             },
        //             {
        //                 override: {
        //                     name: 'Name',
        //                     key: 'name',
        //                     description: ''
        //                 }
        //             },
        //             {
        //                 override: {
        //                     name: 'AssetId',
        //                     key: 'assetId',
        //                     description: ''
        //                 }
        //             }
        //         ]
        //     )
    ];
    // location: {
    //     name: 'Location',
    //     toReadable: (m: Device): string =>
    //         m.location !== null && m.location !== undefined ? m.location : 'Unspecified',
    //     sortable: true
    // },
    // customLocation: {
    //     name: 'Custom Location',
    //     toReadable: (m: Device): string =>
    //         m.customLocation !== null && m.customLocation !== undefined
    //             ? m.customLocation
    //             : 'Unspecified',
    //     sortable: true
    // },
    // macAddr: {
    //     name: 'Mac Address',
    //     toReadable: (m: Device): string =>
    //         m.macAddr !== null && m.macAddr !== undefined ? m.macAddr : 'Unspecified',
    //     sortable: true
    // },
    // ipAddr: {
    //     name: 'IP Address',
    //     toReadable: (m: Device): string =>
    //         m.ipAddr !== null && m.ipAddr !== undefined ? m.ipAddr : 'Unspecified',
    //     sortable: true
    // },
    // model: {
    //     name: 'Model',
    //     toReadable: (m: Device): string =>
    //         m.model !== null && m.model !== undefined ? m.model : 'Unspecified',
    //     sortable: true
    // },
    // make: {
    //     name: 'Make',
    //     toReadable: (m: Device): string =>
    //         m.make !== null && m.make !== undefined ? m.make : 'Unspecified',
    //     sortable: true
    // },
    // serialNum: {
    //     name: 'Serial Number',
    //     toReadable: (m: Device): string =>
    //         m.serialNum !== null && m.serialNum !== undefined ? m.serialNum : 'Unspecified',
    //     sortable: true
    // },
    // 'manualSerialNum.value': {
    //     name: 'Manual Serial Number',
    //     toReadable: (m: Device): string =>
    //         m.manualSerialNum.value !== null && m.manualSerialNum.value !== undefined
    //             ? m.manualSerialNum.value
    //             : 'Unspecified',
    //     sortable: true
    // },
    // 'manualSerialNum.date': {
    //     name: 'Manual Serial Date',
    //     toReadable: (m: Device): string =>
    //         m.manualSerialNum.date !== null && m.manualSerialNum.date !== undefined
    //             ? m.manualSerialNum.date.toLocaleString()
    //             : 'Unspecified',
    //     sortable: true
    // },
    // note: {
    //     name: 'Note',
    //     toReadable: (m: Device): string =>
    //         m.note !== null && m.note !== undefined ? m.note : 'Unspecified',
    //     sortable: true
    // }

    public static foreignKeys: Column.ForeignKeys = {
        [CollectionName.entity]: 'entityKey',
        [CollectionName.install]: 'currentMeterRead.installKey'
    };

    public entityKey: ObjectId | string = null;
    public installKey: string[] = [];
    public itemKey: ObjectId | string = null;
    public name: string = '';
    public assetId: string = '';
    public location: string = '';
    public customLocation: string = '';
    public macAddr: string = '';
    public uMacId?: string;
    public ipAddr: string = '';
    public model: string = '';
    public make: string = '';
    public serialNum: string;
    public customSerialNum: string = null;
    public manualSerialNum: {
        value: string;
        date: Date;
    } = {
        value: '',
        date: null
    };
    public note: string = '';
    public avgFill: number = 0;
    public avgColorFill: number = 0;
    public firmware: DeviceFirmware = { device: '' };
    public counterType: string = '';
    public ignore30Rule: boolean = false;
    public lastReportDtTm: Date = null;
    public currentMeterRead: MeterRead = new MeterRead();
    public events: DeviceEvent[] = [];
    public settings: Partial<Settings> = {};
    public label: Array<DeviceLabels> = [];
    public status: Status = Status.Active;
    public lcdDisplay: string;
    public isLocal: boolean = false;
    public isUsbPlus: boolean = false;
    public skipAlerts: IDeviceSkipAlert[] = [];
    public integrationID: string = '';
    public dynamicDeviceGroup?: string;
    public sdsDeviceIds: { [installKey: string]: DeviceSdsId } = {};

    constructor(params?: Partial<DeviceConstructorParams>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.entityKey = params.entityKey || this.entityKey;
            if (Array.isArray(params.installKey)) {
                this.installKey = params.installKey;
            }
            this.itemKey = params.itemKey || this.itemKey;
            this.name = params.name || this.name;
            this.assetId = params.assetId || this.assetId;
            this.location = params.location || this.location;
            this.customLocation = params.customLocation || this.customLocation;
            this.macAddr = params.macAddr || this.macAddr;
            this.uMacId = params.uMacId || this.uMacId;
            this.ipAddr = params.ipAddr || this.ipAddr;
            this.model = params.model || this.model;
            this.make = params.make || this.make;
            this.serialNum = params.serialNum || this.serialNum;
            this.customSerialNum = params.customSerialNum || this.customSerialNum;
            if (!!params.manualSerialNum) {
                this.manualSerialNum.value =
                    params.manualSerialNum.value || this.manualSerialNum.value;
                if (!!params.manualSerialNum.date) {
                    this.manualSerialNum.date = new Date(params.manualSerialNum.date);
                }
            }
            this.note = params.note || this.note;
            this.avgFill = params.avgFill || this.avgFill;
            this.avgColorFill = params.avgColorFill || this.avgColorFill;
            this.firmware = params.firmware || this.firmware;
            this.counterType = params.counterType || this.counterType;
            this.ignore30Rule = params.ignore30Rule || this.ignore30Rule;
            if (!!params.lastReportDtTm) {
                this.lastReportDtTm = new Date(params.lastReportDtTm);
            }
            if (!!params.currentMeterRead) {
                this.currentMeterRead = new MeterRead(params.currentMeterRead);
            }
            if (Array.isArray(params.events)) {
                for (const e of params.events) {
                    this.events = [...this.events, new DeviceEvent(e)];
                }
            }
            if (!!params.settings) {
                this.settings = params.settings;
            }
            this.label = params.label || this.label;
            this.status = params.status || this.status;
            this.lcdDisplay = params.lcdDisplay || this.lcdDisplay;
            this.isLocal = params.isLocal || this.isLocal;
            this.isUsbPlus = params.isUsbPlus ?? this.isUsbPlus;
            if (Array.isArray(params.skipAlerts)) {
                for (const a of params.skipAlerts) {
                    this.skipAlerts = [...this.skipAlerts, new DeviceSkipAlert(a)];
                }
            }
            this.integrationID = params.integrationID || this.integrationID;
            this.dynamicDeviceGroup = params.dynamicDeviceGroup;
            if (params.sdsDeviceIds) {
                for (const installKey in params.sdsDeviceIds) {
                    this.sdsDeviceIds[installKey] = new DeviceSdsId(params.sdsDeviceIds[installKey]);
                }
            }
        }
    }

    public getKeys(): any {
        return { deviceKey: this._id, entityKey: this.entityKey };
    }
}
