<ng-container *ngIf="!!formGroup; else noFormGroup">
    <form [formGroup]="formGroup">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title i18n>
                        Device alerts
                    </mat-panel-title>
                    <mat-panel-description i18n>
                        Toggle alerts for supplies and services; set thresholds and email contacts
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <h3 i18n>Supply alerts</h3>
                <section>
                    <ptkr-field-group
                        label="Low supply alerts"
                        hint="If you would like to receive alerts to the alert emails attached to your account"
                        i18n-label
                        i18n-hint
                    >
                        <ptkr-inheritance-reset-button
                            [settingName]="SettingsKeyMap.Alerts.Toner.AlertToggle"
                            [showDropdown]="showDropdown"
                            (bulkReset)="runBulkReset.emit($event)"
                        >
                        </ptkr-inheritance-reset-button>
                        <div class="mat-toggle-container" style="position: relative;">
                            <mat-slide-toggle
                                class="toggle-field-infix m-r-5"
                                id="device-lowSupply-toggle"
                                [formControlName]="SettingsKeyMap.Alerts.Toner.AlertToggle"
                            >
                                <ng-container i18n="@@enabled">Enabled</ng-container>
                                <div class="slide-toggle-trigger-overlay" matRipple></div>
                            </mat-slide-toggle>
                        </div>
                    </ptkr-field-group>
                    <div class="-indent">
                        <ptkr-field-group
                            label="Notification emails"
                            hint="The default email to send low supply alerts to"
                            i18n-label
                            i18n-hint
                        >
                            <ptkr-inheritance-reset-button
                                [settingName]="SettingsKeyMap.Alerts.Toner.Emails.Alert"
                                [showDropdown]="showDropdown"
                                (bulkReset)="runBulkReset.emit($event)"
                            >
                            </ptkr-inheritance-reset-button>
                            <ptkr-overwritable-email-array
                                [formControlName]="SettingsKeyMap.Alerts.Toner.Emails.Alert"
                                [inheritedEmails]="
                                    getField(SettingsKeyMap.Alerts.Toner.Emails.Alert)
                                        .inheritedState
                                "
                                [isOverwriting]="
                                    getField(SettingsKeyMap.Alerts.Toner.Emails.Alert).overwrite
                                "
                                [notifications]="notifications"
                                (overwrite)="setOverwrite(SettingsKeyMap.Alerts.Toner.Emails.Alert)"
                            >
                            </ptkr-overwritable-email-array>
                        </ptkr-field-group>
                        <ptkr-field-group
                            label="Toner/ink threshold"
                            hint="Receive an alert when a supply level drops below this amount"
                            i18n-label
                            i18n-hint
                        >
                            <ptkr-inheritance-reset-button
                                [settingName]="SettingsKeyMap.Alerts.Toner.PctRemaining"
                                [showDropdown]="showDropdown"
                                (bulkReset)="runBulkReset.emit($event)"
                            >
                            </ptkr-inheritance-reset-button>
                            <mat-form-field appearance="outline">
                                <span
                                    matPrefix
                                    style="font-size: 1.5em; color: rgba(0,0,0,0.25); margin-right: 0.25em;"
                                    >%</span
                                >
                                <input
                                    matInput
                                    type="number"
                                    min="0"
                                    max="100"
                                    [formControlName]="SettingsKeyMap.Alerts.Toner.PctRemaining"
                                />
                            </mat-form-field>
                        </ptkr-field-group>
                        <ptkr-field-group
                            label="Separate color threshold"
                            hint="If enabled, a different color threshold will be used for color supplies"
                            i18n-label
                            i18n-hint
                        >
                            <ptkr-inheritance-reset-button
                                [settingName]="SettingsKeyMap.Alerts.Toner.SeparateColorThreshold"
                                [showDropdown]="showDropdown"
                                (bulkReset)="runBulkReset.emit($event)"
                            >
                            </ptkr-inheritance-reset-button>
                            <div class="mat-toggle-container" style="position: relative;">
                                <mat-slide-toggle
                                    class="toggle-field-infix m-r-5"
                                    [formControlName]="
                                        SettingsKeyMap.Alerts.Toner.SeparateColorThreshold
                                    "
                                >
                                    <ng-container i18n="@@enabled">Enabled</ng-container>
                                    <div class="slide-toggle-trigger-overlay" matRipple></div>
                                </mat-slide-toggle>
                            </div>
                        </ptkr-field-group>
                        <div class="-indent">
                            <ptkr-field-group
                                label="Color alert threshold"
                                hint="Receive an alert when a color supply level drops below this amount"
                                i18n-label
                                i18n-hint
                                [hidden]="
                                    !getFieldValue(
                                        SettingsKeyMap.Alerts.Toner.SeparateColorThreshold
                                    )
                                "
                            >
                                <ptkr-inheritance-reset-button
                                    [settingName]="SettingsKeyMap.Alerts.Toner.ColorPctRemaining"
                                    [showDropdown]="showDropdown"
                                    (bulkReset)="runBulkReset.emit($event)"
                                >
                                </ptkr-inheritance-reset-button>
                                <mat-form-field appearance="outline">
                                    <span
                                        matPrefix
                                        style="font-size: 1.5em; color: rgba(0,0,0,0.25); margin-right: 0.25em;"
                                        >%</span
                                    >
                                    <input
                                        matInput
                                        type="number"
                                        min="0"
                                        max="100"
                                        [formControlName]="
                                            SettingsKeyMap.Alerts.Toner.ColorPctRemaining
                                        "
                                    />
                                </mat-form-field>
                            </ptkr-field-group>
                        </div>
                        <ptkr-field-group
                            label="Suspected alerts"
                            hint="A suspected supply alert is an alert that looks like it might be a supply alert but may need human-intervention to verify"
                            i18n-label
                            i18n-hint
                        >
                            <ptkr-inheritance-reset-button
                                [settingName]="SettingsKeyMap.Alerts.Toner.SuspectedAlertToggle"
                                [showDropdown]="showDropdown"
                                (bulkReset)="runBulkReset.emit($event)"
                            >
                            </ptkr-inheritance-reset-button>
                            <div class="mat-toggle-container" style="position: relative;">
                                <mat-slide-toggle
                                    class="toggle-field-infix m-r-5"
                                    id="device-suspectedLowSupply-toggle"
                                    [formControlName]="
                                        SettingsKeyMap.Alerts.Toner.SuspectedAlertToggle
                                    "
                                >
                                    <ng-container i18n="@@enabled">Enabled</ng-container>
                                    <div class="slide-toggle-trigger-overlay" matRipple></div>
                                </mat-slide-toggle>
                            </div>
                        </ptkr-field-group>
                        <ptkr-field-group
                            label="Maintenance supply alerts"
                            hint="Send email when maintenance supplies such as fuser, waste, oil, etc. run low"
                            i18n-label
                            i18n-hint
                        >
                            <ptkr-inheritance-reset-button
                                [settingName]="SettingsKeyMap.Alerts.Service.Type.MaintKit"
                                [showDropdown]="showDropdown"
                                (bulkReset)="runBulkReset.emit($event)"
                            >
                            </ptkr-inheritance-reset-button>
                            <div class="mat-toggle-container" style="position: relative;">
                                <mat-slide-toggle
                                    class="toggle-field-infix m-r-5"
                                    id="device-maintenanceSupply-toggle"
                                    [formControlName]="SettingsKeyMap.Alerts.Service.Type.MaintKit"
                                >
                                    <ng-container i18n="@@enabled">Enabled</ng-container>
                                    <div class="slide-toggle-trigger-overlay" matRipple></div>
                                </mat-slide-toggle>
                            </div>
                        </ptkr-field-group>
                        <div class="-indent">
                            <ptkr-field-group
                                label="Maintenance supply threshold"
                                hint="Maintenance supply emails will be sent if a maintenance supply such as fuser, waste, oil,
                              etc. run below this threshold"
                                i18n-label
                                i18n-hint
                                [hidden]="
                                    !getFieldValue(SettingsKeyMap.Alerts.Service.Type.MaintKit)
                                "
                            >
                                <ptkr-inheritance-reset-button
                                    [settingName]="
                                        SettingsKeyMap.Alerts.Service.MaintKitPctRemaining
                                    "
                                    [showDropdown]="showDropdown"
                                    (bulkReset)="runBulkReset.emit($event)"
                                >
                                </ptkr-inheritance-reset-button>
                                <mat-form-field appearance="outline">
                                    <span
                                        matPrefix
                                        style="font-size: 1.5em; color: rgba(0,0,0,0.25); margin-right: 0.25em;"
                                        >%</span
                                    >
                                    <input
                                        matInput
                                        type="number"
                                        min="0"
                                        max="100"
                                        [formControlName]="
                                            SettingsKeyMap.Alerts.Service.MaintKitPctRemaining
                                        "
                                    />
                                </mat-form-field>
                            </ptkr-field-group>
                        </div>
                    </div>
                    <ptkr-field-group
                        label="Replacement"
                        hint="Send alerts when a consumable such as a toner, ink, drum, etc. is replaced"
                        i18n-label
                        i18n-hint
                    >
                        <ptkr-inheritance-reset-button
                            [settingName]="SettingsKeyMap.Alerts.Toner.ReplacementToggle"
                            [showDropdown]="showDropdown"
                            (bulkReset)="runBulkReset.emit($event)"
                        >
                        </ptkr-inheritance-reset-button>
                        <div class="mat-toggle-container" style="position: relative;">
                            <mat-slide-toggle
                                class="toggle-field-infix m-r-5"
                                id="device-replacement-toggle"
                                [formControlName]="SettingsKeyMap.Alerts.Toner.ReplacementToggle"
                            >
                                <ng-container i18n="@@enabled">Enabled</ng-container>
                                <div class="slide-toggle-trigger-overlay" matRipple></div>
                            </mat-slide-toggle>
                        </div>
                    </ptkr-field-group>
                    <div class="-indent">
                        <ptkr-field-group
                            label="Notification emails"
                            hint="The default email to send replacement alerts to"
                            i18n-label
                            i18n-hint
                            [hidden]="!getFieldValue(SettingsKeyMap.Alerts.Toner.ReplacementToggle)"
                        >
                            <ptkr-inheritance-reset-button
                                [settingName]="SettingsKeyMap.Alerts.Toner.Emails.Replacement"
                                [showDropdown]="showDropdown"
                                (bulkReset)="runBulkReset.emit($event)"
                            >
                            </ptkr-inheritance-reset-button>
                            <ptkr-overwritable-email-array
                                [formControlName]="SettingsKeyMap.Alerts.Toner.Emails.Replacement"
                                [inheritedEmails]="
                                    getField(SettingsKeyMap.Alerts.Toner.Emails.Replacement)
                                        .inheritedState
                                "
                                [isOverwriting]="
                                    getField(SettingsKeyMap.Alerts.Toner.Emails.Replacement)
                                        .overwrite
                                "
                                [notifications]="notifications"
                                (overwrite)="
                                    setOverwrite(SettingsKeyMap.Alerts.Toner.Emails.Replacement)
                                "
                            >
                            </ptkr-overwritable-email-array>
                        </ptkr-field-group>
                    </div>
                    <ptkr-field-group
                        label="Premature replacement"
                        hint="Send alerts when a consumable such as a toner, ink, drum, etc. is replaced before it reaches the level in the toner/ink threshold setting"
                        i18n-label
                        i18n-hint
                    >
                        <ptkr-inheritance-reset-button
                            [settingName]="SettingsKeyMap.Alerts.Toner.PrematureToggle"
                            [showDropdown]="showDropdown"
                            (bulkReset)="runBulkReset.emit($event)"
                        >
                        </ptkr-inheritance-reset-button>
                        <div class="mat-toggle-container" style="position: relative;">
                            <mat-slide-toggle
                                class="toggle-field-infix m-r-5"
                                id="device-premature-toggle"
                                [formControlName]="SettingsKeyMap.Alerts.Toner.PrematureToggle"
                            >
                                <ng-container i18n="@@enabled">Enabled</ng-container>
                                <div class="slide-toggle-trigger-overlay" matRipple></div>
                            </mat-slide-toggle>
                        </div>
                    </ptkr-field-group>
                    <div class="-indent">
                        <ptkr-field-group
                            label="Notification emails"
                            hint="The default email to send premature replacement alerts to"
                            i18n-label
                            i18n-hint
                            [hidden]="!getFieldValue(SettingsKeyMap.Alerts.Toner.PrematureToggle)"
                        >
                            <ptkr-inheritance-reset-button
                                [settingName]="SettingsKeyMap.Alerts.Toner.Emails.Premature"
                                [showDropdown]="showDropdown"
                                (bulkReset)="runBulkReset.emit($event)"
                            >
                            </ptkr-inheritance-reset-button>
                            <ptkr-overwritable-email-array
                                [formControlName]="SettingsKeyMap.Alerts.Toner.Emails.Premature"
                                [inheritedEmails]="
                                    getField(SettingsKeyMap.Alerts.Toner.Emails.Premature)
                                        .inheritedState
                                "
                                [isOverwriting]="
                                    getField(SettingsKeyMap.Alerts.Toner.Emails.Premature).overwrite
                                "
                                [notifications]="notifications"
                                (overwrite)="
                                    setOverwrite(SettingsKeyMap.Alerts.Toner.Emails.Premature)
                                "
                            >
                            </ptkr-overwritable-email-array>
                        </ptkr-field-group>
                    </div>

                    <hr />
                    <h3 i18n>Service alerts</h3>
                    <!--                    <ptkr-field-group-->
                    <!--                        label='Not reachable'-->
                    <!--                        hint='Send email when print tracker cannot connect to a device'>-->
                    <!--                        <ptkr-inheritance-reset-button-->
                    <!--                            [settingName]='SettingsKeyMap.Alerts.Service.Type.NotReachable'-->
                    <!--                            [showDropdown]='showDropdown'-->
                    <!--                            (bulkReset)='runBulkReset.emit($event)'>-->
                    <!--                        </ptkr-inheritance-reset-button>-->
                    <!--                        <div class='mat-toggle-container' style='position: relative;'>-->
                    <!--                            <mat-slide-toggle class='toggle-field-infix' id='device-notReachable-toggle'-->
                    <!--                                              [formControlName]='SettingsKeyMap.Alerts.Service.Type.NotReachable'>-->
                    <!--                                Enabled-->
                    <!--                                <div class='slide-toggle-trigger-overlay' matRipple></div>-->
                    <!--                            </mat-slide-toggle>-->
                    <!--                        </div>-->
                    <!--                    </ptkr-field-group>-->
                    <ptkr-field-group
                        label="Service recommended/required"
                        hint="Send email when the device requires a service technician"
                        i18n-label
                        i18n-hint
                    >
                        <ptkr-inheritance-reset-button
                            [settingName]="SettingsKeyMap.Alerts.Service.Type.ServiceRequested"
                            [showDropdown]="showDropdown"
                            (bulkReset)="runBulkReset.emit($event)"
                        >
                        </ptkr-inheritance-reset-button>
                        <div class="mat-toggle-container" style="position: relative;">
                            <mat-slide-toggle
                                class="toggle-field-infix m-r-5"
                                id="device-deviceAlert-toggle"
                                [formControlName]="
                                    SettingsKeyMap.Alerts.Service.Type.ServiceRequested
                                "
                            >
                                <ng-container i18n="@@enabled">Enabled</ng-container>
                                <div class="slide-toggle-trigger-overlay" matRipple></div>
                            </mat-slide-toggle>
                        </div>
                    </ptkr-field-group>
                    <ptkr-field-group
                        label="Misfeed/paper jams"
                        hint="Send alert when the device has a misfeed or paper jam"
                        i18n-label
                        i18n-hint
                    >
                        <ptkr-inheritance-reset-button
                            [settingName]="SettingsKeyMap.Alerts.Service.Misfeed.Enabled"
                            [showDropdown]="showDropdown"
                            (bulkReset)="runBulkReset.emit($event)"
                        >
                        </ptkr-inheritance-reset-button>
                        <div class="mat-toggle-container" style="position: relative;">
                            <mat-slide-toggle
                                class="toggle-field-infix m-r-5"
                                [formControlName]="SettingsKeyMap.Alerts.Service.Misfeed.Enabled"
                            >
                                <ng-container i18n="@@enabled">Enabled</ng-container>
                                <div class="slide-toggle-trigger-overlay" matRipple></div>
                            </mat-slide-toggle>
                        </div>
                    </ptkr-field-group>
                    <ptkr-field-group
                        label="Service alert emails"
                        hint="Service emails will be sent to these addresses"
                        i18n-label
                        i18n-hint
                    >
                        <ptkr-inheritance-reset-button
                            [settingName]="SettingsKeyMap.Alerts.Service.Emails.Alert"
                            [showDropdown]="showDropdown"
                            (bulkReset)="runBulkReset.emit($event)"
                        >
                        </ptkr-inheritance-reset-button>
                        <ptkr-overwritable-email-array
                            [formControlName]="SettingsKeyMap.Alerts.Service.Emails.Alert"
                            [inheritedEmails]="
                                getField(SettingsKeyMap.Alerts.Service.Emails.Alert).inheritedState
                            "
                            [isOverwriting]="
                                getField(SettingsKeyMap.Alerts.Service.Emails.Alert).overwrite
                            "
                            [notifications]="notifications"
                            (overwrite)="setOverwrite(SettingsKeyMap.Alerts.Service.Emails.Alert)"
                        >
                        </ptkr-overwritable-email-array>
                    </ptkr-field-group>

                    <hr />
                    <h3 i18n>Volume alerts</h3>
                    <ptkr-field-group
                        label="Volume threshold alerts"
                        hint="Sends an alert at user-defined volume thresholds"
                        i18n-label
                        i18n-hint
                    >
                        <ptkr-inheritance-reset-button
                            [settingName]="SettingsKeyMap.Alerts.Volume.Enabled"
                            [showDropdown]="showDropdown"
                            (bulkReset)="runBulkReset.emit($event)"
                        >
                        </ptkr-inheritance-reset-button>
                        <div class="mat-toggle-container" style="position: relative;">
                            <mat-slide-toggle
                                class="toggle-field-infix m-r-5"
                                id="device-volumeAlerts-toggle"
                                [formControlName]="SettingsKeyMap.Alerts.Volume.Enabled"
                            >
                                <ng-container i18n="@@enabled">Enabled</ng-container>
                                <div class="slide-toggle-trigger-overlay" matRipple></div>
                            </mat-slide-toggle>
                        </div>
                    </ptkr-field-group>
                    <div
                        class="-indent"
                        *ngIf="getFieldValue(SettingsKeyMap.Alerts.Volume.Enabled)"
                    >
                        <ptkr-field-group
                            label="Recurring"
                            hint="Enables additional alerts after the first threshold is reached"
                            i18n-label
                            i18n-hint
                        >
                            <ptkr-inheritance-reset-button
                                [settingName]="SettingsKeyMap.Alerts.Volume.Recurring"
                                [showDropdown]="showDropdown"
                                (bulkReset)="runBulkReset.emit($event)"
                            >
                            </ptkr-inheritance-reset-button>
                            <div class="mat-toggle-container" style="position: relative;">
                                <mat-slide-toggle
                                    class="toggle-field-infix m-r-5"
                                    id="device-volumeRecurring-toggle"
                                    [formControlName]="SettingsKeyMap.Alerts.Volume.Recurring"
                                >
                                    <ng-container i18n
                                        >Enable these alerts to be recurring</ng-container
                                    >
                                    <div class="slide-toggle-trigger-overlay" matRipple></div>
                                </mat-slide-toggle>
                            </div>
                        </ptkr-field-group>
                        <div style="display: flex; flex-direction: row;">
                            <ptkr-field-group
                                style="margin-right: 2em; flex-grow: 1;"
                                label="Next alert at"
                                hint="Specifies the threshold at which the volume alert will be triggered"
                                i18n-label
                                i18n-hint
                            >
                                <ptkr-inheritance-reset-button
                                    [settingName]="SettingsKeyMap.Alerts.Volume.Beginning"
                                    [showDropdown]="showDropdown"
                                    (bulkReset)="runBulkReset.emit($event)"
                                >
                                </ptkr-inheritance-reset-button>
                                <mat-form-field appearance="outline">
                                    <input
                                        matInput
                                        type="number"
                                        [formControlName]="SettingsKeyMap.Alerts.Volume.Beginning"
                                    />
                                    <mat-hint *ngIf="isDevice">
                                        <span style="font-weight: bold" i18n>Total:</span>
                                        {{
                                            !!defaultTotalPageCounts
                                                ? (defaultTotalPageCounts | number)
                                                : '--'
                                        }}
                                        <span class="m-l-5 m-r-5" i18n> as of </span>
                                        {{ lastReadDtTm | date: 'medium' }}
                                    </mat-hint>
                                </mat-form-field>
                            </ptkr-field-group>
                            <!-- Below we have a ngIf else structure. The goal is to provide a disabled
                                 control to take the place of our volumeFrequency when the control is disabled
                                 but not actually tamper with the state of the control. -->
                            <ng-container *ngIf="isVolumeFrequencyEnabled(); else dummyFrequency">
                                <ptkr-field-group
                                    style="flex-grow: 1;"
                                    label="Frequency"
                                    hint="If recurring is enabled, represents distance between alerts after the first is sent"
                                    i18n-label
                                    i18n-hint
                                >
                                    <ptkr-inheritance-reset-button
                                        [settingName]="SettingsKeyMap.Alerts.Volume.Frequency"
                                        [showDropdown]="showDropdown"
                                        (bulkReset)="runBulkReset.emit($event)"
                                    >
                                    </ptkr-inheritance-reset-button>
                                    <mat-form-field appearance="outline">
                                        <input
                                            matInput
                                            type="number"
                                            min="0"
                                            [formControlName]="
                                                SettingsKeyMap.Alerts.Volume.Frequency
                                            "
                                        />
                                    </mat-form-field>
                                </ptkr-field-group>
                            </ng-container>
                            <ng-template #dummyFrequency>
                                <ptkr-field-group
                                    style="flex-grow: 1;"
                                    label="Frequency"
                                    hint="If recurring is enabled, represents distance between alerts after the first is sent"
                                    i18n-label
                                    i18n-hint
                                >
                                    <ptkr-inheritance-reset-button
                                        [settingName]="SettingsKeyMap.Alerts.Volume.Frequency"
                                        [showDropdown]="showDropdown"
                                        (bulkReset)="runBulkReset.emit($event)"
                                    >
                                    </ptkr-inheritance-reset-button>
                                    <mat-form-field appearance="outline">
                                        <input matInput type="number" min="0" value="0" disabled />
                                    </mat-form-field>
                                </ptkr-field-group>
                            </ng-template>
                        </div>
                        <ptkr-field-group
                            label="Volume alert emails"
                            hint="Volume emails will be sent to these addresses"
                            i18n-label
                            i18n-hint
                        >
                            <ptkr-inheritance-reset-button
                                [settingName]="SettingsKeyMap.Alerts.Volume.Emails"
                                [showDropdown]="showDropdown"
                                (bulkReset)="runBulkReset.emit($event)"
                            >
                            </ptkr-inheritance-reset-button>
                            <ptkr-overwritable-email-array
                                [formControlName]="SettingsKeyMap.Alerts.Volume.Emails"
                                [inheritedEmails]="
                                    getField(SettingsKeyMap.Alerts.Volume.Emails).inheritedState
                                "
                                [isOverwriting]="
                                    getField(SettingsKeyMap.Alerts.Volume.Emails).overwrite
                                "
                                [notifications]="notifications"
                                (overwrite)="setOverwrite(SettingsKeyMap.Alerts.Volume.Emails)"
                            >
                            </ptkr-overwritable-email-array>
                        </ptkr-field-group>
                    </div>
                </section>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title i18n>
                        E-Automate integration
                    </mat-panel-title>
                    <mat-panel-description i18n>
                        Automatically process sales quotes
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <section>
                    <!--Sales Quote Integration Enabled-->
                    <ptkr-field-group
                        label="Sales quote processor"
                        hint="Automatically generate sales quotes for low supplies"
                        i18n-label
                        i18n-hint
                    >
                        <ptkr-inheritance-reset-button
                            [settingName]="SettingsKeyMap.Integrations.EAutomate.SalesQuote.Enabled"
                            [showDropdown]="showDropdown"
                            (bulkReset)="runBulkReset.emit($event)"
                        >
                        </ptkr-inheritance-reset-button>
                        <div class="bg-orange-100 text-orange-700 py-1 px-2 rounded" i18n>
                            Enabling this integration may incur additional costs on your monthly
                            invoice. This feature is only available to customers with an active
                            E-Automate integration using the Print Tracker Data Processor. Please
                            contact your support representative for additional questions.
                        </div>
                        <div class="mat-toggle-container" style="position: relative;">
                            <mat-slide-toggle
                                class="toggle-field-infix m-r-5"
                                [formControlName]="
                                    SettingsKeyMap.Integrations.EAutomate.SalesQuote.Enabled
                                "
                            >
                                <ng-container i18n="@@enabled">Enabled</ng-container>
                                <div class="slide-toggle-trigger-overlay" matRipple></div>
                            </mat-slide-toggle>
                        </div>
                    </ptkr-field-group>

                    <!--Sales Quote Consolidation Within Days-->
                    <!--                    <ptkr-field-group-->
                    <!--                        [hidden]="!isSalesQuoteEnabled()"-->
                    <!--                        label="Consolidate within days"-->
                    <!--                        hint="Automatically consolidate supplies whose estimated depletion date is within this many days of its estimated depletion threshold"-->
                    <!--                        i18n-label-->
                    <!--                        i18n-hint-->
                    <!--                    >-->
                    <!--                        <ptkr-inheritance-reset-button-->
                    <!--                            [settingName]="-->
                    <!--                                SettingsKeyMap.Supplies.Consolidation.WithinDaysRemaining-->
                    <!--                            "-->
                    <!--                            [showDropdown]="showDropdown"-->
                    <!--                            (bulkReset)="runBulkReset.emit($event)"-->
                    <!--                        >-->
                    <!--                        </ptkr-inheritance-reset-button>-->
                    <!--                        <mat-form-field appearance="outline">-->
                    <!--                            <input-->
                    <!--                                matInput-->
                    <!--                                type="number"-->
                    <!--                                [formControlName]="-->
                    <!--                                    SettingsKeyMap.Supplies.Consolidation.WithinDaysRemaining-->
                    <!--                                "-->
                    <!--                            />-->
                    <!--                        </mat-form-field>-->
                    <!--                    </ptkr-field-group>-->

                    <!--Sales Quote Consolidation Within Percent-->
                    <ptkr-field-group
                        [hidden]="!isSalesQuoteEnabled()"
                        label="Consolidate within percent"
                        hint="Automatically consolidate supplies whose current level is within this percent of it's low supply alert threshold"
                        i18n-label
                        i18n-hint
                    >
                        <ptkr-inheritance-reset-button
                            [settingName]="
                                SettingsKeyMap.Supplies.Consolidation.WithinPercentRemaining
                            "
                            [showDropdown]="showDropdown"
                            (bulkReset)="runBulkReset.emit($event)"
                        >
                        </ptkr-inheritance-reset-button>
                        <mat-form-field appearance="outline">
                            <span
                                matPrefix
                                style="font-size: 1.5em; color: rgba(0,0,0,0.25); margin-right: 0.25em;"
                                >%</span
                            >
                            <input
                                matInput
                                type="number"
                                min="0"
                                max="100"
                                [formControlName]="
                                    SettingsKeyMap.Supplies.Consolidation.WithinPercentRemaining
                                "
                            />
                        </mat-form-field>
                    </ptkr-field-group>
                </section>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title i18n>
                        Billing preferences
                    </mat-panel-title>
                    <mat-panel-description i18n>
                        Favored meter formats, counters, and schedule
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <section>
                    <ptkr-field-group
                        label="Billing meters"
                        hint="Billing preferences determine the order of format priority (equiv, life, etc) and the
                              counters that should be added to this device's billing meters"
                        i18n-label
                        i18n-hint
                    >
                        <ptkr-inheritance-reset-button
                            [settingName]="SettingsKeyMap.BillingPreferences.Counters"
                            [showDropdown]="showDropdown"
                            (bulkReset)="runBulkReset.emit($event)"
                        >
                        </ptkr-inheritance-reset-button>
                        <ptkr-bmp-editor
                            [formControlName]="SettingsKeyMap.BillingPreferences.Counters"
                            [devicePageCounts]="billingMeterOptions"
                            [offsetEnabled]="isDevice"
                        ></ptkr-bmp-editor>
                    </ptkr-field-group>
                    <ptkr-field-group
                        label="Toggle billing schedule"
                        hint="Enable to allow us to record dates where billing should occur; You'll be able to look up
                          meter information by date and see only devices you want to bill for that date, along with
                          the data collected during the previous billing cycle"
                        i18n-label
                        i18n-hint
                    >
                        <ptkr-inheritance-reset-button
                            [settingName]="SettingsKeyMap.BillingPreferences.Schedule.Enabled"
                            [showDropdown]="showDropdown"
                            (bulkReset)="runBulkReset.emit($event)"
                        >
                        </ptkr-inheritance-reset-button>
                        <div class="mat-toggle-container" style="position: relative;">
                            <mat-slide-toggle
                                class="toggle-field-infix m-r-5"
                                id="device-schedule-toggle"
                                [formControlName]="
                                    SettingsKeyMap.BillingPreferences.Schedule.Enabled
                                "
                            >
                                <ng-container i18n="@@enabled">Enabled</ng-container>
                                <div class="slide-toggle-trigger-overlay" matRipple></div>
                            </mat-slide-toggle>
                        </div>
                    </ptkr-field-group>
                    <ptkr-field-group
                        label="Billing schedule"
                        hint="On the dates described by this schedule, this device will appear when
                              viewing meters by billing date"
                        i18n-label
                        i18n-hint
                        [hidden]="
                            !getFieldValue(SettingsKeyMap.BillingPreferences.Schedule.Enabled)
                        "
                    >
                        <ptkr-inheritance-reset-button
                            [settingName]="SettingsKeyMap.BillingPreferences.Schedule.Expression"
                            (reverted)="onCronReverted()"
                            [showDropdown]="showDropdown"
                            (bulkReset)="runBulkReset.emit($event)"
                        ></ptkr-inheritance-reset-button>
                        <div>
                            <p>
                                <span style="font-weight: 700" i18n>Current schedule:</span>
                                {{ prettyCron }}
                            </p>
                            <ptkr-cron-editor
                                [formControlName]="
                                    SettingsKeyMap.BillingPreferences.Schedule.Expression
                                "
                                [options]="cronOptions"
                                (cronChange)="onCronUpdate($event)"
                            ></ptkr-cron-editor>
                        </div>
                    </ptkr-field-group>
                    <ptkr-field-group
                        label="Schedule timezone"
                        hint="The timezone in which the billing schedule operates"
                        i18n-label
                        i18n-hint
                        [hidden]="
                            !getFieldValue(SettingsKeyMap.BillingPreferences.Schedule.Enabled)
                        "
                    >
                        <ptkr-inheritance-reset-button
                            [settingName]="SettingsKeyMap.BillingPreferences.Schedule.Timezone"
                            [showDropdown]="showDropdown"
                            (bulkReset)="runBulkReset.emit($event)"
                        >
                        </ptkr-inheritance-reset-button>
                        <mat-form-field appearance="outline">
                            <mat-select
                                [formControlName]="
                                    SettingsKeyMap.BillingPreferences.Schedule.Timezone
                                "
                            >
                                <mat-option
                                    *ngFor="let timezone of timezoneOptions"
                                    [value]="timezone"
                                >
                                    {{ timezone }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ptkr-field-group>
                </section>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title i18n>
                        Auto-fulfillment
                    </mat-panel-title>
                    <mat-panel-description i18n>
                        Supplies Network settings
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <section>
                    <p style='font-family:"Open Sans",serif;' i18n>
                        Supplies Network auto-fulfillment should only be enabled if you have already
                        configured auto-fulfillment with Supplies Network. Contact
                        <a href="https://printtrackerpro.com/contact">Print Tracker support</a> if
                        you are enabling this feature for your organization for the first time.
                    </p>
                    <ptkr-field-group
                        label="Supplies Network"
                        hint="Enables auto-fulfillment integration with supplies network"
                        i18n-label
                        i18n-hint
                    >
                        <ptkr-inheritance-reset-button
                            [settingName]="SettingsKeyMap.AutoFulfillment.SuppliesNetwork.Enabled"
                            [showDropdown]="showDropdown"
                            (bulkReset)="runBulkReset.emit($event)"
                        >
                        </ptkr-inheritance-reset-button>
                        <div class="mat-toggle-container" style="position: relative;">
                            <mat-slide-toggle
                                class="toggle-field-infix m-r-5"
                                id="device-supplies-network-toggle"
                                [formControlName]="
                                    SettingsKeyMap.AutoFulfillment.SuppliesNetwork.Enabled
                                "
                            >
                                <ng-container i18n="@@enabled">Enabled</ng-container>
                                <div class="slide-toggle-trigger-overlay" matRipple></div>
                            </mat-slide-toggle>
                        </div>
                    </ptkr-field-group>
                </section>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title i18n>
                        Device webpage credentials
                    </mat-panel-title>
                    <mat-panel-description i18n>
                        Remote Access Settings
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <section>
                    <p style='font-family:"Open Sans",serif;' i18n>
                        The credentials entered below will be used by the Data Collection Agent when
                        reading meter data from a device if the meter read data cannot be extracted
                        otherwise.
                    </p>
                    <ptkr-field-group label="Username" i18n-label>
                        <ptkr-inheritance-reset-button
                            [settingName]="
                                SettingsKeyMap.DataGathering.EmbeddedWebpage.Credentials.Username
                            "
                            [showDropdown]="showDropdown"
                            (bulkReset)="runBulkReset.emit($event)"
                        >
                        </ptkr-inheritance-reset-button>
                        <mat-form-field appearance="outline">
                            <input
                                matInput
                                type="text"
                                [formControlName]="
                                    SettingsKeyMap.DataGathering.EmbeddedWebpage.Credentials
                                        .Username
                                "
                            />
                        </mat-form-field>
                    </ptkr-field-group>
                    <ptkr-field-group label="Password" i18n-label>
                        <ptkr-inheritance-reset-button
                            [settingName]="
                                SettingsKeyMap.DataGathering.EmbeddedWebpage.Credentials.Password
                            "
                            [showDropdown]="showDropdown"
                            (bulkReset)="runBulkReset.emit($event)"
                        >
                        </ptkr-inheritance-reset-button>
                        <mat-form-field appearance="outline">
                            <input
                                matInput
                                type="text"
                                [formControlName]="
                                    SettingsKeyMap.DataGathering.EmbeddedWebpage.Credentials
                                        .Password
                                "
                            />
                        </mat-form-field>
                    </ptkr-field-group>
                </section>
            </mat-expansion-panel>
        </mat-accordion>
    </form>
</ng-container>
<ng-template #noFormGroup>
    <div>
        <h2 style="color: gray;" i18n>Loading...</h2>
    </div>
</ng-template>
