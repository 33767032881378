export enum InstallSdsStatus {
    Disabled = 'Disabled',
    Disconnected = 'Disconnected',
    Connected = 'Connected',
}

export namespace InstallSdsStatus {
    export function fromString(string: string): InstallSdsStatus {
        switch (string) {
            case InstallSdsStatus.Disabled:
                return InstallSdsStatus.Disabled;
            case InstallSdsStatus.Disconnected:
                return InstallSdsStatus.Disconnected;
            case InstallSdsStatus.Connected:
                return InstallSdsStatus.Connected;
            default:
                throw new Error(`Unknown install sds status: ${string}`);
        }
    }

    export function toDisplay(status: InstallSdsStatus): string {
        switch (status) {
            case InstallSdsStatus.Disabled:
                return 'Disabled';
            case InstallSdsStatus.Disconnected:
                return 'Disconnected';
            case InstallSdsStatus.Connected:
                return 'Connected';
            default:
                throw new Error(`Unknown install sds status: ${status}`);
        }
    }
}
