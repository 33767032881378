import { Component, Input } from '@angular/core';
import { DayOfWeek, SettingsTimeFrame, DynamicSettingsKeyType, Permission } from '@libs/iso/core';
import { InstallDynamicSettingsSectionFormGroup } from '@libs/web/forms/models';
import { AbstractSettingsSectionComponent } from '../abstract-settings-section/abstract-settings-section.component';
import { AbstractControl } from '@angular/forms';
import { COMMA, ENTER, SEMICOLON, TAB } from '@angular/cdk/keycodes';
import { InstallService, NotificationService, OverlayService } from '@app/services';
import { filter, switchMap } from 'rxjs/operators';

@Component({
    selector: 'ptkr-install-dynamic-settings-section',
    templateUrl: './install-dynamic-settings-section.component.html',
    styleUrls: ['./install-dynamic-settings-section.component.scss']
})
export class InstallDynamicSettingsSectionComponent extends AbstractSettingsSectionComponent<
    InstallDynamicSettingsSectionFormGroup
> {
    @Input() public isInstall: boolean = false;
    @Input() public isDaemon: boolean = false;

    // If the settings panel is for an install, this will be the install key.
    @Input() public installKey: string = null;

    public SettingsTimeFrame: typeof SettingsTimeFrame = SettingsTimeFrame;
    public DayOfWeek: typeof DayOfWeek = DayOfWeek;
    public separatorKeysCodes: any = [ENTER, COMMA, SEMICOLON, TAB];
    public Permission: typeof Permission = Permission;

    constructor(
        private _overlayService: OverlayService,
        private _installService: InstallService,
        private _notificationService: NotificationService
    ) {
        super();
    }

    // TODO: Move this logic into it's own suite of form control components.
    public checkboxArrayHandler(event: any, value: any, type: DynamicSettingsKeyType): void {
        const control: AbstractControl = this.getField(type);
        control.markAsDirty();

        let controlValue: number[] = control.value;
        if (controlValue === null || controlValue === undefined) {
            controlValue = [];
        }
        if (event.checked === true) {
            control.setValue([...controlValue, value]);
        } else {
            const index = control.value.indexOf(value);
            const newValue = [...control.value];
            newValue.splice(index, 1);
            control.setValue(newValue);
        }
    }

    public isWeekdayChecked(day): boolean {
        const val = this.getFieldValue(
            this.SettingsKeyMap.DeviceDiscovery.AutoScanForNewDevices.DaysOfWeek
        );
        return (
            day !== null &&
            day !== undefined &&
            val !== undefined &&
            val !== null &&
            val.indexOf(day) > -1
        );
    }

    public openSdsSetDefaultSettingsModal(): void {
        this._overlayService
            .openSdsSetDefaultSettings(this.installKey)
            .afterClosed()
            .pipe(
                filter(result => !!result),
                switchMap(result => {
                    return this._installService.sdsSetDefaultDeviceSettings(
                        this.installKey,
                        result
                    );
                })
            )
            .subscribe({
                next: () => {
                    this._notificationService.success(
                        'Successfully sent default settings to HP SDS'
                    );
                },
                error: err => {
                    console.error(err);
                    this._notificationService.error(err.error ?? err);
                }
            });
    }
}
