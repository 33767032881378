import { IBaseModel, BaseModel } from '../BaseModel';
import { ObjectId } from 'bson';
import { ISettings, Settings } from '../settings/Settings';
import { IInstallContact, InstallContact } from './InstallContact';
import { StatusDcaType, OsArchitecture } from '../../enums';
import { Column } from '../../helpers/columns';
import * as moment from 'moment';
import {
    IInstallHealthCheck,
    InstallHealthCheck
} from '@libs/iso/core/models/install/InstallHealthCheck';
import { CollectionName } from '../../enums/CollectionName';
import { InstallSds, IReportingDevices } from '@libs/iso/core';

export interface IInstall extends IBaseModel {
    entityKey: ObjectId | string;
    legacyId: number;
    legacyPCode: string;
    legacyKey: string;
    lastCheckInDtTm: moment.Moment;
    nextCheckInDtTm: moment.Moment;
    lastCheckIn: {};
    contact: IInstallContact;
    notes: string;
    location: string;
    modDtTm: Date | moment.Moment;
    machine: string;
    os: string;
    osVersion: string;
    cpuArchitecture: OsArchitecture;
    remoteIP: string;
    serverIP: string;
    versionDCA: string;
    installDate: Date | moment.Moment;
    shutdownDate: Date | moment.Moment;
    uninstallDate: Date | moment.Moment;
    upgradedDate: Date | moment.Moment;
    statusDCA: StatusDcaType;
    localDeviceCount: number;
    networkDeviceCount: number;
    settings: Partial<ISettings>;
    entityName?: string;
    hasBattery?: boolean;
    healthcheck?: IInstallHealthCheck;
    isLocal?: boolean;
    moduleVersions?: { [key: string]: string };
    reportingDevices?: IReportingDevices;
    antivirus?: string;
    sds?: InstallSds;
}

// Install represents data that is sent up from a Data Collection Agent (DCA), except for some fields that contain instructions for a
// specific DCA, upgrade, shutdownDate, and current settings.  These settings are set from the Admin UI.
//   NOTE: Site entity settings are what is requested of the DCA, while Install records are what the DCA believes is current.
// Install records are created from the DCAService module, from getLicenseKey and getUnhandledSettings via direct db insert, and
// from getInstallationId via AddInstallFromDcaDto.
// Install records are updated by the DCA via postSettings, updateNextReportDtTm, postCounts, and similar calls.

export class Install extends BaseModel implements IInstall {
    public static columns: Array<Column> = [
        ...BaseModel.columns
        // entityKey: {
        //     name: 'Entity Key',
        //     toReadable: (m: Install): string =>
        //         m.entityKey !== null && m.entityKey !== undefined
        //             ? m.entityKey.toString()
        //             : 'Unspecified',
        //     sortable: true
        // },
        // lastCheckInDtTm: {
        //     name: 'Last Check-In',
        //     toReadable: (m: Install): string =>
        //         m.lastCheckInDtTm !== null && m.lastCheckInDtTm !== undefined
        //             ? m.lastCheckInDtTm.toLocaleString()
        //             : 'Unspecified',
        //     sortable: true
        // },
        // nextCheckInDtTm: {
        //     name: 'Next Check-In',
        //     toReadable: (m: Install): string =>
        //         m.lastCheckInDtTm !== null && m.lastCheckInDtTm !== undefined
        //             ? m.lastCheckInDtTm.toLocaleString()
        //             : 'Unspecified',
        //     sortable: true
        // },
        // notes: {
        //     name: 'Notes',
        //     toReadable: (m: Install): string =>
        //         m.notes !== null && m.notes !== undefined ? m.notes : 'Unspecified'
        // },
        // location: {
        //     name: 'Location',
        //     toReadable: (m: Install): string =>
        //         m.location !== null && m.location !== undefined ? m.location : 'Unspecified'
        // },
        // machine: {
        //     name: 'Machine',
        //     toReadable: (m: Install): string =>
        //         m.machine !== null && m.machine !== undefined ? m.location : 'Unspecified'
        // },
        // os: {
        //     name: 'Operating System',
        //     toReadable: (m: Install): string =>
        //         m.os !== null && m.os !== undefined ? m.os : 'Unspecified'
        // },
        // osVersion: {
        //     name: 'OS Version',
        //     toReadable: (m: Install): string =>
        //         m.osVersion !== null && m.osVersion !== undefined ? m.osVersion : 'Unspecified'
        // },
        // cpuArchitecture: {
        //     name: 'CPU Architecture',
        //     toReadable: (m: Install): string =>
        //         m.cpuArchitecture !== null && m.cpuArchitecture !== undefined
        //             ? m.cpuArchitecture
        //             : 'Unspecified'
        // },
        // remoteIP: {
        //     name: 'Remote IP',
        //     toReadable: (m: Install): string =>
        //         m.cpuArchitecture !== null && m.cpuArchitecture !== undefined
        //             ? m.cpuArchitecture
        //             : 'Unspecified'
        // },
        // serverIP: {
        //     name: 'Server IP',
        //     toReadable: (m: Install): string =>
        //         m.serverIP !== null && m.cpuArchitecture !== undefined ? m.serverIP : 'Unspecified'
        // },
        // versionDCA: {
        //     name: 'DCA Version',
        //     toReadable: (m: Install): string =>
        //         m.versionDCA !== null && m.versionDCA !== undefined ? m.versionDCA : 'Unspecified'
        // },
        // entityName: {
        //     name: 'Name',
        //     toReadable: (m: Install): string =>
        //         m.entityName !== null && m.entityName !== undefined ? m.entityName : 'Unspecified',
        //     sortable: true
        // }
    ];

    public static foreignKeys: Column.ForeignKeys = {
        [CollectionName.entity]: 'entityKey'
    };

    public entityKey: string | ObjectId = null;
    public legacyId: number = 0;
    public legacyPCode: string = '';
    public legacyKey: string; // in the format of XXXX-XXX-XXXX
    public lastCheckInDtTm: moment.Moment = null;
    public nextCheckInDtTm: moment.Moment = null;
    public lastCheckIn: {};
    public contact: IInstallContact = new InstallContact();
    public notes: string = '';
    public location: string = '';
    public modDtTm: Date | moment.Moment = null;
    public machine: string = '';
    public os: string = '';
    public osVersion: string = '';
    public cpuArchitecture: OsArchitecture;
    public remoteIP: string = '';
    public serverIP: string = '';
    public versionDCA: string = '';
    public installDate: Date | moment.Moment = null;
    public shutdownDate: Date | moment.Moment = null;
    public uninstallDate: Date | moment.Moment = null;
    public upgradedDate: Date | moment.Moment = null;
    public statusDCA: StatusDcaType = StatusDcaType.Evaluation;
    public localDeviceCount: number = 0;
    public networkDeviceCount: number = 0;
    public settings: Partial<Settings> = {};
    public entityName?: string;
    public hasBattery?: boolean;
    public healthcheck?: InstallHealthCheck = new InstallHealthCheck();
    public isLocal?: boolean = false;
    public moduleVersions?: { [key: string]: string } = {};
    public reportingDevices?: IReportingDevices = {
        totalDevices: 0,
        reportingDevices: 0,
        percentage: 0
    };
    public antivirus?: string;
    public sds?: InstallSds = new InstallSds({});

    constructor(defaults?: Partial<IInstall>) {
        super(defaults);
        if (defaults) {
            this.entityKey = defaults.entityKey || this.entityKey;
            this.legacyKey = defaults.legacyKey || this.legacyKey;
            this.legacyId = defaults.legacyId || this.legacyId;
            this.legacyPCode = defaults.legacyPCode || this.legacyPCode;
            this.lastCheckInDtTm = defaults.lastCheckInDtTm || this.lastCheckInDtTm;
            this.nextCheckInDtTm = defaults.nextCheckInDtTm || this.nextCheckInDtTm;
            this.lastCheckIn = defaults.lastCheckIn || this.lastCheckIn;
            this.contact = defaults.contact || this.contact;
            this.notes = defaults.notes || this.notes;
            this.location = defaults.location || this.location;
            this.modDtTm = defaults.modDtTm || this.modDtTm;
            this.machine = defaults.machine || this.machine;
            this.os = defaults.os || this.os;
            this.osVersion = defaults.osVersion || this.osVersion;
            this.cpuArchitecture = defaults.cpuArchitecture || this.cpuArchitecture;
            this.remoteIP = defaults.remoteIP || this.remoteIP;
            this.serverIP = defaults.serverIP || this.serverIP;
            this.versionDCA = defaults.versionDCA || this.versionDCA;
            this.installDate = defaults.installDate || this.installDate;
            this.shutdownDate = defaults.shutdownDate || this.shutdownDate;
            this.uninstallDate = defaults.uninstallDate || this.uninstallDate;
            this.upgradedDate = defaults.upgradedDate || this.upgradedDate;
            this.statusDCA = defaults.statusDCA || this.statusDCA;
            this.localDeviceCount = defaults.localDeviceCount || this.localDeviceCount;
            this.networkDeviceCount = defaults.networkDeviceCount || this.networkDeviceCount;
            this.hasBattery = defaults.hasBattery || this.hasBattery;
            this.healthcheck = defaults.healthcheck || this.healthcheck;
            this.isLocal = defaults.isLocal || this.isLocal;
            this.moduleVersions = defaults.moduleVersions || this.moduleVersions;
            this.antivirus = defaults.antivirus || this.antivirus;
            if (!!defaults.settings) {
                this.settings = defaults.settings;
            }
            if (!!defaults.entityName) {
                this.entityName = defaults.entityName;
            }
            if (!!defaults.reportingDevices) {
                this.reportingDevices = defaults.reportingDevices;
            }
            if (!!defaults.sds) {
                this.sds = new InstallSds(defaults.sds);
            }
        }
    }
}
